import axios from 'axios';
import { message } from 'antd';
import { redirectToPassport } from '@common/utils';

function responseInterceptors(response) {
  const { error_code, error_msg } = response.data;
  switch (error_code) {
    case 11052:
    case 11051:
      message.error(error_msg);
      const redirectTimer = setTimeout(() => {
        clearTimeout(redirectTimer);
        redirectToPassport();
      }, 1500);
    default:
      return response.data;
  }
}
const api = axios.create({
  baseURL: '/api',
});
api.interceptors.response.use(responseInterceptors, (error) => {
  return Promise.reject(error);
});

const newApi = axios.create({
  baseURL: '/api',
});
newApi.interceptors.response.use(
  (response) => {
    const { error_code, error_msg } = response.data;
    if (error_code) {
      let res = responseInterceptors(response.data);
      res && message.error(error_msg);
      throw response.data;
    }
    return response.data.data || response.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
export const xiangxinApi = newApi;

export * from './common';
export * from './order';
export * from './menu';
export * from './wallet';
export * from './invoice';
export * from './staff';
export * from './role';
export * from './balance';
export * from './box';
export * from './driver';
export * from './substitutecushion';
