export const redirectToPassport = () => {
  const from = encodeURIComponent(window.location.href);
  location.href = `${process.env.REACT_APP_PASSPORT_URL}?from=${from}`;
};

export const getYearRange = (range = 10) => {
  const currentYear = new Date().getFullYear();
  const options = [];
  for (let index = 0; index <= range; index++) {
    options.push(currentYear - index);
  }
  return options;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
export const getSearch = () => {
  const { search } = location;
  const obj = {};
  if (!search) return obj;
  const [, str] = search.split('?');
  const searchArr = str.split('&');
  searchArr.forEach((item) => {
    const [key, val] = item.split('=');
    obj[key] = val;
  });
  return obj;
};
// 保留两位小数
export const toFixed = (num = 0) => {
  return Math.round(num * 100) / 100 || 0;
};
