import { get } from 'lodash';
import moment from 'moment';
import api from '@common/api';
import { getFormatDateRangeValues } from '@common/utils';

export const getContainerReleaseOrderPrice = ({
  importAndExportType,
  shipEnterpriseId,
  shipAgentId,
  containerYardId,
  needDelivery,
  containers = [],
}) => {
  return api.post('/convoy/dockser/price', {
    import_type: importAndExportType,
    ship_enter_id: shipEnterpriseId,
    ship_agent_id: shipAgentId,
    yard_id: containerYardId,
    is_need_arrive: Number(needDelivery),
    boxs: containers.map((container) => ({
      box_size_type: container.size,
      count: container.counts,
    })),
  });
};

export const postContainerReleaseOrder = ({
  port,
  importAndExportType,
  ladingNumber,
  productionDate,
  containers = [],
  shippingDate,
  shipEnterpriseId,
  voyageNumber,
  xx_ship_ticket_id,
  ladingFile,
  switchBillDate,
  needDelivery,
  shipAgentId,
  containerYardId,
  switchBillRemark,
  totalPrice,
  doclaimUrl,
  is_xianhuanxianzou,
  ship_ticket_desc_seted,
}) => {
  return api.post('/convoy/dockser', {
    scope: port,
    import_type: importAndExportType,
    tidan_code: ladingNumber,
    product_time:
      productionDate && moment(productionDate).isValid()
        ? moment(productionDate).format('YYYY-MM-DD HH:00')
        : undefined,
    ship_ticket_desc: shippingDate
      ? shippingDate.format('YYYY-MM-DD')
      : undefined,
    ship_ticket: voyageNumber,
    xx_ship_ticket_id,
    file: ladingFile,
    ship_enter_id: shipEnterpriseId,
    ship_agent_id: shipAgentId,
    yard_id: containerYardId,
    is_need_arrive: Number(needDelivery),
    boxs: containers.map((container) => ({
      box_size_type: container.size,
      count: container.counts,
    })),
    ship_ticket_desc_seted,
    work_time: switchBillDate.format('YYYY-MM-DD'),
    remark: switchBillRemark,
    price: totalPrice,
    d_o_claim_url: doclaimUrl,
    is_xianhuanxianzou: is_xianhuanxianzou ? 1 : 0,
  });
};

export const getContainerReleaseOrder = ({
  page,
  switchBillDate = [],
  leavedAndArrivedDate = [],
  loadAndUnloadDate = [],
  importAndExport,
  ladingNo,
  shipEnterprise,
  voyageNumber,
  switchBillAgency,
  switchBillContainerYard,
  orderStatus,
  receiptType,
  recallStatus,
  createDate = [],
}) => {
  const params = {
    page,
    work_time_start: switchBillDate[0],
    work_time_end: switchBillDate[1],
    ship_ticket_desc_start: leavedAndArrivedDate[0],
    ship_ticket_desc_end: leavedAndArrivedDate[1],
    product_time_start: loadAndUnloadDate[0],
    product_time_end: loadAndUnloadDate[1],
    import_type: importAndExport,
    tidan_code: ladingNo,
    ship_company_id: shipEnterprise,
    ship_ticket: voyageNumber,
    ship_agent_id: switchBillAgency,
    yard_id: switchBillContainerYard,
    receipt_type: receiptType,
    recede_not_submit: recallStatus,
    create_time_start: createDate[0],
    create_time_end: createDate[1],
  };
  if (orderStatus > -1) {
    params.order_status = orderStatus;
  } else if (orderStatus === -1) {
    params.payment_status = 1;
  } else if (orderStatus === -2) {
    params.exception_status = 1;
  }

  return api.get('/convoy/dockser', {
    params,
  });
};

export const getContainerReleaseOrderDetail = (orderId) => {
  return api.get(`/convoy/dockser/${orderId}`);
};

export const postContainerReleaseOrderCancel = (orderId) => {
  return api.post('/convoy/dockser/cancel', {
    dockserve_order_id: orderId,
  });
};

export const postContainerReleaseOrderApplyCheck = ({ orderId, date }) => {
  return api.post('/convoy/dockser/helian/apply', {
    id: orderId,
    time: date.format('YYYY-MM-DD'),
  });
};

export const getContainerReleaseOrderCancelContainers = (orderId) => {
  return api.get(`/convoy/dockser/cancel/${orderId}`);
};

export const postContainerReleaseOrderCancelContainers = ({
  orderId,
  containerIdList,
}) => {
  return api.post('/convoy/dockser/cancel/box', {
    dockserve_order_id: orderId,
    dockserve_box_ids: containerIdList,
  });
};

export const getContainerReleaseOrderExceptionDetail = (exceptionId) => {
  return api.get(`/convoy/dockser/abnormal/${exceptionId}`);
};

export const postContainerReleaseOrderExceptionDeal = ({
  exceptionId,
  comment,
}) => {
  return api.post(`/convoy/dockser/abnormal/${exceptionId}/deal`, {
    remark: comment,
  });
};

/**
 * @param {number} containerId
 * @param {number} source // 1 - 箱信端 2 - 车队服务端
 */
export const getContainerReleaseOrderFeeList = ({
  containerId,
  source = 2,
}) => {
  return api.get(`/convoy/dockser/financial/box/${containerId}`, {
    params: {
      from_business: source,
    },
  });
};

export const getContainerGateInOrderPrice = ({
  harborDistrictId,
  containers,
  shipEnterpriseId,
  shipId,
  shipTicketId,
  paymentMethodType,
  stock_in_purpose,
  stock_in_purpose_serve_type,
  tizhongYardId,
  huikongYardId,
  tikongYardId,
}) => {
  const data = {
    wharf_district_id: harborDistrictId || 0,
    ship_enter_id: shipEnterpriseId || 0,
    ship_id: shipId || 0,
    xx_ship_ticket_id: shipTicketId || 0,
    payment_method_type: paymentMethodType,
    boxs: containers.map((container) => ({
      yard_id: container.dropContainerYardId,
      box_size_type: container.size,
      stock_in_purpose: container.stock_in_purpose || undefined,
      stock_in_purpose_serve_type:
        container.stock_in_purpose_serve_type &&
        container.stock_in_purpose_serve_type.length > 0
          ? container.stock_in_purpose_serve_type?.toString()
          : undefined,
      tizhong_yard_id: container.tizhong_yard_id || undefined,
      huikong_yard_id: container.huikong_yard_id || undefined,
      tikong_yard_id: container.tikong_yard_id || undefined,
    })),
    stock_in_purpose: stock_in_purpose || undefined,
    stock_in_purpose_serve_type: stock_in_purpose_serve_type || undefined,
    tizhong_yard_id: tizhongYardId || undefined,
    huikong_yard_id: huikongYardId || undefined,
    tikong_yard_id: tikongYardId || undefined,
  };
  return api.post('/cargocvge/price/huodong/quoted/price', data);
};

export const postContainerGateInOrder = (params) => {
  const data = {
    scope: params.portId,
    import_type: params.importAndExportType,
    trade_type: params.tradeType,
    wharf_id: get(params, 'wharfId.1'),
    tidan_code: params.ladingNumber,
    ship_enter_id: params.shipEnterpriseId,
    ship_id: get(params, 'shipNameId', 0),
    ship_name_en: get(params, 'shipName.english', ''),
    ship_name_cn: get(params, 'shipName.chinese', ''),
    ship_ticket: params.voyageNumber,
    xx_ship_ticket_id: params.xx_ship_ticket_id,
    pod_id: params.destinationHarborId,
    unload_dock_id: params.UnDockId,
    ship_ticket_desc: params.leavePortDate?.format('YYYY-MM-DD'),
    product_category: params.productCategoryId,
    remark: params.remark,
    payment_method_type: params.paymentMethod,
    price:
      params.totalPrice != params.huodongTotal
        ? params.huodongTotal || 0
        : params.totalPrice || 0,
    ship_ticket_desc_seted: params.ship_ticket_desc_seted,
    boxs: params.containers.map((container) => ({
      box_code: container.containerNumber || '',
      box_ensupe: container.sealNumber || '',
      box_size_type: container.size,
      product_pcs: container.counts,
      product_foot: container.volume,
      product_weight: container.weight,
      tare_weight: container.tare,
      yard_id: container.dropContainerYardId,
      // add1_id: container.packContainerAddressId[0],
      // add2_id: container.packContainerAddressId[1],
      add1_id: 3538,
      add2_id: 3539,
      car_number: container.driverNumber || '',
      chuchang_car_number: container.chuchangCarNumber || '',
      plan_date: container.planDate.format('YYYY-MM-DD'),
      plan_time: container.planDateType,
    })),
    product_name: params.productName,
    stock_in_purpose: params.stock_in_purpose || undefined, // 进场目的
    stock_in_purpose_serve_type:
      params.stock_in_purpose_serve?.toString() || undefined,
    tizhong_yard_id: params.tizhong_yard_id || undefined,
    huikong_yard_id: params.huikong_yard_id || undefined,
    tikong_yard_id: params.tikong_yard_id || undefined,
  };
  return api.post('/convoy/cargocvge/order', data);
};

export const postContainerGateInOrderAddContainers = ({
  orderId,
  price,
  containers = [],
}) => {
  return api.post('/convoy/cargocvge/order/boxs', {
    order_id: orderId,
    price,
    boxs: containers.map((container) => ({
      box_code: container.containerNumber || '',
      box_ensupe: container.sealNumber || '',
      box_size_type: container.size,
      product_pcs: container.counts,
      product_foot: container.volume,
      product_weight: container.weight,
      tare_weight: container.tare,
      yard_id: container.dropContainerYardId,
      // add1_id: container.packContainerAddressId[0],
      // add2_id: container.packContainerAddressId[1],
      add1_id: 3538,
      add2_id: 3539,
      car_number: container.driverNumber || '',
      plan_date: container.planDate.format('YYYY-MM-DD'),
      plan_time: container.planDateType,
      stock_in_purpose: container.stock_in_purpose || undefined,
      stock_in_purpose_serve_type:
        container.stock_in_purpose_serve_type?.toString() || undefined,
      tizhong_yard_id: container.tizhong_yard_id || undefined,
      huikong_yard_id: container.huikong_yard_id || undefined,
      tikong_yard_id: container.tikong_yard_id || undefined,
    })),
  });
};

/**
 * @param {string} type box_code 箱号 | tidan_code 提单号
 */
export const getContainerGateInOrderSearchOptions = ({ type, search }) => {
  return api.get('/convoy/cargocvge/order/search', {
    params: {
      type,
      value: search,
    },
  });
};

export const getContainerGateInOrder = ({
  orderStatus,
  page,
  createdDate,
  leavedPortDate,
  ladingNumber,
  containerNumber,
  shipNameId,
  voyageNumber,
  isComplet,
}) => {
  const createdTimeRange = getFormatDateRangeValues(createdDate, 'YYYY-MM-DD');
  const leavedPortDateRage = getFormatDateRangeValues(
    leavedPortDate,
    'YYYY-MM-DD',
  );
  return api.get('/convoy/cargocvge/order', {
    params: {
      page,
      status: orderStatus,
      create_time_begin: createdTimeRange[0],
      create_time_end: createdTimeRange[1],
      ship_ticket_desc_begin: leavedPortDateRage[0],
      ship_ticket_desc_end: leavedPortDateRage[1],
      tidan_code: ladingNumber,
      box_codes: containerNumber,
      ship_id: shipNameId,
      ship_ticket: voyageNumber,
      is_complet: isComplet,
    },
  });
};

export const getContainerGateInOrderBox = ({
  orderStatus,
  page,
  createdDate,
  // leavedPortDate,
  ladingNumber,
  containerNumber,
  shipNameId,
  voyageNumber,
  isComplet,
}) => {
  const createdTimeRange = getFormatDateRangeValues(createdDate, 'YYYY-MM-DD');
  // const leavedPortDateRage = getFormatDateRangeValues(
  //   leavedPortDate,
  //   'YYYY-MM-DD',
  // );
  return api.get('/convoy/cargocvge/box', {
    params: {
      page,
      box_status: orderStatus,
      create_time_begin: createdTimeRange[0],
      create_time_end: createdTimeRange[1],
      // ship_ticket_desc_begin: leavedPortDateRage[0],
      // ship_ticket_desc_end: leavedPortDateRage[1],
      tidan_code: ladingNumber,
      box_codes: containerNumber,
      ship_id: shipNameId,
      ship_ticket: voyageNumber,
      is_complet: isComplet,
    },
  });
};

export const getContainerGateInOrderDetail = (orderId) => {
  return api.get(`/convoy/cargocvge/order/${orderId}`);
};

export const getContainerGateInOrderServiceInfo = (orderId) => {
  return api.get('/convoy/cargocvge/order/cgStagedInfo', {
    params: {
      order_id: orderId,
    },
  });
};

export const getGateInOrderYardList = ({
  port,
  search,
  wharfId,
  shipEnterpriseId,
}) => {
  return api.get('/cargocvge/price/yard_list', {
    params: {
      scope: port,
      s: search,
      wharf_district_id: wharfId,
      ship_enter_id: shipEnterpriseId,
    },
  });
};
// 陆运订单列表
export const convoyDsphorderList = (params) => ({
  url: '/convoy/dsphorder',
  params,
});
// 陆运订单列表(新)
export const convoyDsphorderListNew = (params) => ({
  url: '/convoy/dsphorder/new',
  params,
});
// 领取单据-查看箱子单据
export const getBoxDocument = (box_id) => ({
  url: `/convoy/box/${box_id}/document`,
});
// 领取单据-领取单据
export const saveBoxDocument = (box_id, code) => ({
  url: `/convoy/box/${box_id}/document`,
  method: 'post',
  data: { code },
});
// 领取单据跳过
export const ignoreBoxDocumentApi = (box_id) => ({
  url: `/convoy/box/${box_id}/document/ignore`,
  method: 'post',
});
// 订单详情
export const orderDetail = (dsph_order_id) => ({
  url: `/convoy/dsphorder/${dsph_order_id}`,
});
// 保存订单其他照片
export const orderOtherPicture = ({ box_id, pic_info }) => ({
  url: `/convoy/box/${box_id}/upload/pic`,
  method: 'post',
  data: { pic_info },
});
// 迟到报告
export const orderLateReport = (
  box_id,
  { overdue_id, yu_reach_time, type, dsph_order_id },
) => ({
  url: `/convoy/box/${box_id}/overdue`,
  method: 'post',
  data: { yu_reach_time, overdue_id, type, dsph_order_id },
});
// 回复提箱时间
export const replyTixiangApi = ({ box_id, tixiang_time }) => ({
  url: `/convoy/box/${box_id}/reply_tixiang`,
  method: 'put',
  data: { tixiang_time },
});

export const getContainerSupplementDetails = ({ containers }) => {
  const data = {
    boxes: containers.map((container) => ({
      box_id: container.boxId,
      box_code: container.containerNumber,
      box_ensupe: container.sealNumber,
      car_number: container.driverNumber,
      chuchang_car_number: container.chuchang_car_number || '',
    })),
  };
  return api.put('/convoy/cargocvge/order/complet', data);
};

export const getContainerGateInOrderDetails = (hash) => {
  return api.get('/convoy/cargocvge/order/import', {
    params: {
      hash,
    },
  });
};

export const getContainerGateInConvoyListData = () => {
  return api.get('/cargocvge/price/huodong/quoted');
};

export const getContainerGateInConvoyDetailsData = (id) => {
  return api.get(`/cargocvge/price/huodong/${id}`);
};

// 车队pc-箱子信息
export const getConvoyDsphorderBoxInfo = (id) => ({
  url: '/convoy/dsphorder/new/box_info',
  params: {
    dsph_order_id: id,
  },
});

// 车队pc->司机信息
export const getConvoyDsphorderDriverInfo = (id) => ({
  url: '/convoy/dsphorder/new/driver_info',
  params: {
    dsph_order_id: id,
  },
});

// 车队pc->异常处理
export const getConvoyDsphorderUnusualInfo = (id) => {
  return api.get('/convoy/dsphorder/new/handle_info', {
    params: {
      dsph_order_id: id,
    },
  });
};

// 车队pc->费用信息
export const getConvoyDsphorderFeeInfo = (id) => {
  return api.get('/convoy/dsphorder/new/cost_info', {
    params: {
      dsph_order_id: id,
    },
  });
};

// 车队pc->变更费用信息
export const getConvoyDsphorderFeeChangeInfo = (id) => {
  return api.get('/convoy/dsphorder/new/change_cost_info', {
    params: {
      dsph_order_id: id,
    },
  });
};

// 获取箱子路径地址详情
export const getBoxAddressInfo = (id) => {
  return api.get('order/box/products/batch', {
    params: {
      box_ids: id,
    },
  });
};
// 校验提单号是否存在
export const VerifyLadingNumberApi = (params) => {
  return api.get('/order/common/tidan_code', {
    params,
  });
};

export const EclpOrderImportApi = (data) => {
  return {
    url: '/cargocvge/order/import',
    method: 'post',
    data,
  };
};
// 草稿--保存创建放箱订单
export const SaveOrderDataApi = (params) => {
  return api.post('/convoy/dockser/draft', params);
};
// 草稿--获取创建放箱订单
export const GetOrderDataApi = (params) => {
  return api.get('/convoy/dockser/draft', params);
};

// 获取该车队下【未支付】的费用
export const GetConvoyNoPaymentApi = () => {
  return api.get('/convoy/dockser/financial/payment/need');
};

// 单个订单下【未支付】的费用
export const GetOrderNoPaymentApi = (params) => {
  return api.get(`/convoy/dockser/financial/${params.id}/payment/need`);
};

// 单个订单下支付【未支付】的费用
/**
 * @data {
 *  payment_method_type: 1 | 2 | 3 | 4
 * }
 */
export const PayNoPaymentApi = ({ id, ...data }) => {
  return api.post(`/convoy/dockser/financial/${id}/payment`, {
    ...data,
  });
};
// 获取航次接口
export const getShipTicketApi = (params) => {
  return api.get('/order/common/xx_ship_ticket', {
    params,
  });
};

/**
 * @description 校验在线支付是否支付完成 放箱订单付款状态校验
 * @author ZhangYù
 * @date 29/06/2022
 * @param {*} order_id
 */
export const CheckDockserFinancialOrderStatusApi = (order_id) => ({
  method: 'POST',
  url: `/convoy/dockser/financial/${order_id}/online_pay/check`,
});
